.MuiStepIcon-root.MuiStepIcon-completed {
    color:  #5C42DB !important;
}
.MuiStepper-root {
    display: flex;
    padding: 24px;
    padding-left: 0 !important;
    padding-right: 0 !important;
}
.MuiStepLabel-label {
    color: #000000 !important;
}
.barra-titulo {
    background-color: #5C42DB;
    padding: 0.6rem;
    width: 100%;
    max-width: 100%;
    border-radius: 10px;
    color: #fff;
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 2rem;
}
.alinha-direita{
    text-align: right;
}
.alinha-centro{
    text-align: center;
}
.input-hidden {
    display: none;
}
.lista-upload{
    margin-bottom: 1rem !important;
}
.icone-aprovado {
    color: #00b050 !important;
    float: left;
    margin-right: 1rem;
}
.icone-nao-enviado {
    color: #fdc722 !important;
    float: left;
    margin-right: 1rem;
}
.icone-recusado {
    color: #ff0000 !important;
    float: left;
    margin-right: 1rem;
}
.icone-avaliacao {
    color: #5C42DB !important;
    float: left;
    margin-right: 1rem;
}
.barra-legenda {
    background-color: #f2f2f2;
    border-radius: 10px;
    padding: 1rem;
    width: 100%;
    max-width: 100%;
}
.legenda-titulo {
    float: right;
    padding-right: 2px;
}
.subtitulo {
    text-align: center;
}
.box-notoes {
    margin-top: 1rem;
}

.botao-salvar {
    background: #dfe340 !important;
    color: #000000 !important;
    margin: 1rem !important;
    font-size: 0.7rem;
}
.botao-fechar {
    background: #000000 !important;
    color: #fff !important;
    margin: 1rem !important;
    font-size: 0.7rem;
}
.botao-voltar {
    background: #5C42DB !important;
    color: #fff !important;
    margin: 1rem !important;
    font-size: 0.7rem;
}

.botao-modal {
    text-align: center;
}
.botao-4docs {
    margin-top: 1rem;
}

.botao-fatura {
    background-color: #5C42DB !important;
    color: #fff !important;
}

.lupa {
    color: #5C42DB !important;
    position: relative;
    top: 0.4rem;
}

.erro-leitura {
    color: #ff0000;
    font-size: 0.8rem;
}

.div-carregando {
    margin-top: 1rem;
    max-width: 100%;
    text-align: center;
}
.div-carregando span {
    position: relative;
    bottom: 16px;
    color: #5C42DB;
    font-weight: bold;
    margin-right: 1rem;
}
.carregando {
    color: #EAEF22 !important;
}
.bola-recarregar {
    width: 36px !important;
    height: 36px !important;
    margin-left: 1rem !important;
}
.legenda-fab {
    margin-left: 0.6rem;
}
.botao-viabilidade {
    margin-top: 2rem;
    text-align: center;
    margin-bottom: 2rem;
}
.botao-calcular {
    background-color: #5C42DB !important;
    color: #fff !important;
}
.box-cenario {
    background-color: #f2f2f2;
    padding: 1rem;
    margin-bottom: 1rem;
    min-height: 16rem;
}
.header-cenario {
    background-color: #EAEF22 !important;
    border-color: #000000;
    border-style: solid;
    border-radius: 10px;
    min-height: 2.2rem;
}

.cor-radio {
    color: #000000 !important;
    background: white !important;
    width: 1rem;
    height: 1rem;
    margin: 0.4rem !important;
}
.radio-cenario label {
    color: #000000 !important;
}


.radio-cenario span.MuiTypography-root {
    color: #000000 !important;
    font-weight: bold;
    font-size: 0.88rem;
}

.box-cenario {
    font-weight: bold;
    font-size: 0.86rem;
}

.barra-cenarios {
    margin-top: 2rem;
}

.MuiFormControlLabel-labelPlacementStart {
    max-width: 100% !important;
    width: 100% !important;
    margin-left: 0 !important;
}
.meio-cenario {
    font-weight: bold;
    text-align: center;
    padding-top: 1.3rem !important;
}
.cenario-item {
    margin-bottom: 2rem;
    padding-left: 0.3rem;
    padding-right: 0.3rem;
}
.botao-cenarios {
    text-align: right;
}
.produto-inativo {
    opacity: 0.5 !important;
    margin-bottom: 2rem;
    padding-left: 0.3rem;
    padding-right: 0.3rem;
}
.motivoperdido{
    width: 100%;
    text-align: center;
    font-weight: bold;
    font-size: 1.2rem;
}
.informacao {
    padding: 30px;
    margin: 0 auto;
    margin-bottom: 65px;
    margin-top: 65px;
    border-radius: 20px;
    min-height: 252.42px;
    position: relative;
    background-color: #f2f2f2;
    width: 80%;
    font-size: 1.2rem;
}
.info-azul {
    color: #5c42db;
    font-weight: bold;
}
.info-legenda {
    min-width: 11rem;
}
.infotabela {
    border: 0;
    border-spacing: 0;
    width: 100%;
    color: #fff;
    margin-top: 2rem;
    margin-bottom: 2rem;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    position: relative;
    bottom: 42px;
}
.tr-azul {
    background-color: #5c42db;
    padding: 1rem;
}
.tr-azul td {
    padding: 1rem;
    text-align: center;
}
.tr-normal {
    color: #000000;
    text-align: center;
    background-color: #eaef22;
}
.tr-redondo-left {
    border-top-left-radius: 20px;
}
.tr-redondo-right {
    border-top-right-radius: 20px;
}
.tr-redondo-left-baixo {
    border-bottom-left-radius: 20px;
}
.tr-redondo-right-baixo {
    border-bottom-right-radius: 20px;
}
.tr-titulo {
    width: 15rem;
}
.iconefechado {
    position: relative;
    bottom: 40px;
    right: 51px;
}
.topotabela {
    position: relative;
    bottom: 53px;
}
.info-titulo {
    position: relative;
    bottom: 103px;
    left: 57px;
    color: #000000;
}

.info-titulo span {
    color: #000000;
}
.baixo-legenda{
    position: relative;
    bottom: 40px;
}
.stepzao .MuiStepLabel-label{
    font-size: 1.4rem !important;
}
.stepzao .MuiSvgIcon-root {
    font-size: 2.4rem !important;
}
.cancelarCarga {
    cursor: pointer;
}
.stepzao .MuiStepIcon-root.MuiStepIcon-active {
    color: #e9ef00;
}
.stepzao .MuiStepIcon-text {
    fill: #000000 !important;
}
.boxunidade {
    margin-top: 2rem;
}
.botao-docusign {
    background-color: #5C42DB !important;
    color: #fff !important;
    margin: 1rem !important;
}

.box-botao-caixa {
    background-color: #f2f2f2;
    padding: 1rem;
    border-radius: 10px;
    margin-bottom: 10px;
    text-align: center;
}
.box-cenario {
    background-color: #f2f2f2;
    padding: 0;
    margin-bottom: 1rem;
    min-height: 0;
}
.stepzao .MuiSvgIcon-root {
    font-size: 1.4rem !important;
}
.nulo-margem {
    margin-top: 2rem;
}