.ola_parceiroecom {
    color: #5C42DB;
    font-weight: bolder;
    font-size: 2rem;
}
.ola_busca {
    color: #5C42DB;
    font-weight: bolder;
    font-size: 1.5rem;
}
.topo-menu {
    height: 100px !important;
}
.hr {
    color: #5C42DB;
    border-color: #5C42DB;
}
.botao-chamativo {
    width: 100%;
    color: #5C42DB !important;
}
.icone-busca {
    width: 2rem;
    position: absolute;
    bottom: -17px;
    left: 85%;
}
#razao_social {
    padding-right: 3rem;
}
.h1-titulo {
    font-size: 1.2rem;
    text-align: center;
    margin-bottom: 1rem;
}
.papelfinger-webapp {
    width: 50%;
    margin: 0 auto;
} 
.footer-vazio {
    background-color: #5C42DB;
}
.botao-voltar {
    border-color: #5C42DB;
    background-color: #F5F5F5;
    color: #5C42DB;
}
.container-webpp {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
}
.step-content {
    padding-left: 0 !important;
    border: none !important;
    border-left: none !important;
}
.obrigatorio-webapp {
    background-color: #d6d6d6 !important;
}
/* *:required {
    background-color: #d6d6d6 !important;
  } */

.botao-voltar-webapp {
    margin-top: 1rem !important;
    border-color: #5C42DB !important;
    background-color: #F5F5F5 !important;
    color: #5C42DB !important;
    margin-right: 1rem !important;
}
.botao-salvar-webapp {
    margin-top: 1rem !important;
    border-color: #5C42DB !important;
    background-color: #5C42DB !important;
    color: #EAEF22 !important;
}
.alinha-centro{
    text-align: center !important;
} 
.botao-fatura-webapp {
    margin-top: 1rem !important;
    border-color: #5C42DB !important;
    background-color: #5C42DB !important;
    color: #EAEF22 !important;
}
.stepzaoWebapp {
    width: 100%;
    text-align: center;
    padding: 0 !important;
}

.MuiStepConnector-lineVertical {
    min-height: 0 !important;
    border-left-style: none !important;
    border-left-width: 0 !important;
}
.box-captura-webapp {
    text-align: left;
    padding-top: 2rem;
}
.box-ver-webapp {
    padding-top: 1rem;
    padding-bottom: 2rem;
}
.ola_busca-webapp {
    color: #5C42DB;
    font-weight: bolder;
    font-size: 1.5rem;
    text-align: left;
}
.nome-fantasia-webapp {
    font-weight: bold;
    text-align: left;
    font-size: 1.4rem;
}
.oculto-webapp {
    display: none;
}
.voltar-para-home-webapp {
    margin-top: 3rem;
}
.image-preview img {
    max-width: 100%;
}
.headerwebapp {
    height: 96px;
    box-shadow: none;
    transition: margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    background-color: #5C42DB !important;
}
.icone-captura {
    margin: 0 0 auto;
    width: 2rem;
}
.texto-captura {
    text-align: center;
}
.box-captura {
    width: 100%;
    max-width: 100%;
    text-align: center;
}
.espacocima {
    margin-top: 1rem;
}
.empresascadastradaswebapp {
    background: rgb(51, 102, 204) !important;
    color: #F5F5F5 !important;
    text-align: center;
    height: 5rem !important;
}

.indicacoesrealizadaswebapp {
    background: rgb(220, 57, 18) !important;
    color: #F5F5F5 !important;
    text-align: center;
    height: 5rem !important;
}
.emnegociacaowebapp {
    background: rgb(255, 153, 0) !important;
    color: #F5F5F5 !important;
    text-align: center;
    height: 5rem !important;
}
.clientesfechadoswebapp {
    background: rgb(16, 150, 24) !important;
    color: #F5F5F5 !important;
    text-align: center;
    height: 5rem !important;
}
.numeroswebapp {
    font-weight: bold;
    margin: 0 0 auto;
    font-size: 2rem !important;
}
.legendagraficowebapp {
    font-size: 0.67rem !important;
}
.boxOlho {
    margin-top: 2rem;
}
.gridWebApp {
    padding-right: 6px !important;
}
.grid-centrao {
    text-align: center;
}