/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

@font-face {
  font-family: "Lexend bold";
  src: url("./assets/fonts/Lexend-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Lexend light";
  src: url("./assets/fonts/Lexend-Light.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Lexend Semibold";
  src: url("./assets/fonts/Lexend-SemiBold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Lexend medium";
  src: url("./assets/fonts/Lexend-Medium.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Lexend roman";
  src: url("./assets/fonts/Lexend-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat italic";
  src: url("./assets/fonts/Montserrat-MediumItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

* {
  box-sizing: border-box;
  scrollbar-width: thin;
  scrollbar-color: #626366;
}

*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
*::-webkit-scrollbar-track {
  background: #62636640;
}
*::-webkit-scrollbar-thumb {
  background-color: #62636660;
  border-radius: 20px;
}

html,
body,
#root {
  height: 100%;
  width: 100%;
  font-size: 16px;
  overflow: hidden;
  background-color: #ffffff;
}

body {
  margin: 0;
  font-family: "neue roman", "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.legendaobs {
  font-size: 1rem;
}